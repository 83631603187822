<template>
  <div class="container mt-5 pb-4">
    <CompanyAdminReporting />
  </div>
</template>

<script>

// Components
import CompanyAdminReporting from '@/vue/components/organism/CompanyAdmin/CompanyAdminReporting/CompanyAdminReporting';

export default {
  components: {
    CompanyAdminReporting,
  },
};

</script>
