<template>
  <MainViewLayout>
    <template #main-content>
      <div
        class="phLayoutSettings LayoutPage"
        data-test-id="company_admin-view"
      >
        <div class="GenericTabs GenericTabs--Business">
          <div class="w-100">
            <MuiTabs
              :active="currentTabIndex"
              :tabs="visibleTabs"
              class="TabsContent pt-5"
              @active-tab="goTo"
            />
          </div>
        </div>
      </div>
    </template>
  </MainViewLayout>
</template>
<script>
import size from 'lodash/size';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import first from 'lodash/first';
import get from 'lodash/get';
import { navigationErrorHandler } from '@emobg/web-utils';
import { MuiTabs } from '@emobg/vue-base';

import MainViewLayout from '@/templates/MainLayout/MainLayout';
import { setLoaderStatus } from '@Shared/store/Loader/LoaderMapper';
import { isPersonalProfileActive } from '@/stores/User/Profile/ProfileMapper';
import { getCompanyData } from '@/stores/Company/CompanyMapper';

import CompanyAdminPaymentMethodMixin from '@/mixins/CompanyAdminPaymentMethod';

import CompanyAdminRoutesNames from '@Profile/Business/CompanyAdmin/router/routes-names';
import PersonalProfileRoutesNames from '@Profile/Personal/router/routes-names';
import BookingRoutesNames from '@Bookings/router/routes-names';

import { checkRedirectFlowOnRouteEnter, checkUrlQueryOnPageLeave, removeCommonStorageOnRouterLeave } from '@Shared/Payments/PaymentMethodsHub/composables/responseUtils';
import { computeCompanyAdminTabs } from './helpers/companyAdminHelpers';

export default {
  name: 'CompanyAdmin',

  components: {
    MainViewLayout,
    MuiTabs,
  },

  mixins: [
    CompanyAdminPaymentMethodMixin,
  ],

  provide() {
    return {
      usingCompanyAdmin: true,
    };
  },

  beforeRouteLeave(_to, _from, next) {
    removeCommonStorageOnRouterLeave();

    next();
  },

  beforeRouteEnter(to, from, next) {
    checkRedirectFlowOnRouteEnter(from, to);

    next();
  },

  data() {
    return {
      TABS: [],
      visibleTabs: [],
    };
  },

  computed: {
    isPersonalProfileActive,
    getCompanyData,

    currentTabIndex() {
      return findIndex(this.visibleTabs, { url: this.$route.name });
    },
  },

  watch: {
    isPersonalProfileActive(isActive) {
      if (isActive) {
        this.goTo(PersonalProfileRoutesNames.personalProfile);
      }
    },
  },

  created() {
    const hasDedicatedFleet = get(this.getCompanyData, 'company_fleets.dedicated', false);
    this.TABS = computeCompanyAdminTabs({ hasDedicatedFleet });

    this.visibleTabs = filter(this.TABS, 'isVisible');
    const routeNames = values(CompanyAdminRoutesNames);
    const isValidRoute = routeNames.includes(this.$route.name);
    const isValidTab = !isEmpty(find(this.visibleTabs, ['url', this.$route.name]));

    if (!size(this.visibleTabs)) {
      this.$router.replace({ name: BookingRoutesNames.home }).catch(navigationErrorHandler);
    } else if ((!isValidTab && isValidRoute) || !isValidRoute) {
      const firstVisibleTab = get(first(this.visibleTabs), 'url');
      this.$router.replace({ name: firstVisibleTab }).catch(navigationErrorHandler);
    }
  },

  mounted() {
    this.setLoaderStatus(false);
    checkUrlQueryOnPageLeave();
  },

  methods: {
    setLoaderStatus,

    goTo(routeName) {
      this.$router.push({ name: routeName }).catch(navigationErrorHandler);
    },
  },
};
</script>
