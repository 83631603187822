import { aclService } from '@emobg/access-utils';

import { useTranslations } from '@/composable/App/Translations';

import {
  VIEW_BOOKINGS_COMPANY_EMPLOYEES,
  VIEW_COMPANY_INVOICES,
  VIEW_COMPANY_REPORTS,
  VIEW_COMPANY_SETTINGS,
  VIEW_COMPANY_TARIFF,
  VIEW_EMPLOYEES,
  VIEW_FLEET_VEHICLES,
  VIEW_PAYMENT_METHODS,
} from '@/constants/permissions';

import CompanyAdminRoutesNames from '@Profile/Business/CompanyAdmin/router/routes-names';
import PaymentMethodsTab from '@Shared/Payments/PaymentMethodsTab';

import EmployeeListTab from '../EmployeeListTab';
import InvoicesTab from '../InvoicesTab';
import BookingOverviewTab from '../BookingOverviewTab';
import FleetListTab from '../FleetListTab';
import CompanySettingsTab from '../CompanySettingsTab';
import ReportingTab from '../ReportingTab';
import TariffsTab from '../TariffsTab';

const { $t } = useTranslations();

export const computeCompanyAdminTabs = ({ hasDedicatedFleet }) => [
  {
    id: 'employee',
    label: $t('business_profile.employee.tab'),
    content: [
      {
        component: EmployeeListTab,
      },
    ],
    url: CompanyAdminRoutesNames.employeeList,
    isVisible: aclService.hasPermissions(VIEW_EMPLOYEES),
  },
  {
    id: 'invoices',
    label: $t('business_profile.invoice.tab'),
    content: [
      {
        component: InvoicesTab,
      },
    ],
    url: CompanyAdminRoutesNames.invoices,
    isVisible: aclService.hasPermissions(VIEW_COMPANY_INVOICES),
  },
  {
    id: 'fleet',
    label: $t('business_profile.fleet.tab'),
    content: [
      {
        component: FleetListTab,
      },
    ],
    url: CompanyAdminRoutesNames.fleetList,
    isVisible: aclService.hasPermissions(VIEW_FLEET_VEHICLES) && hasDedicatedFleet,
  },
  {
    id: 'booking_overview',
    label: $t('business_profile.booking_overview.tab'),
    content: [
      {
        component: BookingOverviewTab,
      },
    ],
    url: CompanyAdminRoutesNames.bookingOverview,
    isVisible: aclService.hasUserPermissions(VIEW_BOOKINGS_COMPANY_EMPLOYEES) && hasDedicatedFleet,
  },
  {
    id: 'reports',
    label: $t('business_profile.reporting.tab'),
    content: [
      {
        component: ReportingTab,
      },
    ],
    url: CompanyAdminRoutesNames.reporting,
    isVisible: aclService.hasPermissions(VIEW_COMPANY_REPORTS) && hasDedicatedFleet,
  },
  {
    id: 'payment_methods',
    label: $t('business_profile.payment_method.tab'),
    content: [
      {
        component: PaymentMethodsTab,
      },
    ],
    url: CompanyAdminRoutesNames.paymentMethods,
    isVisible: aclService.hasPermissions(VIEW_PAYMENT_METHODS) && !hasDedicatedFleet,
  },

  {
    id: 'settings',
    label: $t('business_profile.company.tab'),
    content: [
      {
        component: CompanySettingsTab,
      },
    ],
    url: CompanyAdminRoutesNames.settings,
    isVisible: aclService.hasPermissions(VIEW_COMPANY_SETTINGS),
  },
  {
    id: 'tariffs',
    label: $t('business_profile.tariff.tab'),
    content: [
      {
        component: TariffsTab,
      },
    ],
    url: CompanyAdminRoutesNames.tariffs,
    isVisible: aclService.hasPermissions(VIEW_COMPANY_TARIFF) && !hasDedicatedFleet,
  },
];
