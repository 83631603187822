<template>
  <div
    data-test-id="invoices-tab"
    class="container mt-5 mb-4"
  >
    <h2 class="mb-3">
      {{ $t("personal_profile.invoice.title") }}
    </h2>

    <InvoicesTable
      has-filters
      has-checks
    />
  </div>
</template>

<script>
import InvoicesTable from '@/vue/components/organism/Invoices/InvoicesTable';

export default {
  components: {
    InvoicesTable,
  },
};
</script>
