<template>
  <ui-modal
    :open="isOpen"
    :size="SIZES.small"
    :header="$t('business_profile.employee.modal_add_keycard_title')"
    closable
    class="AddEmployeeBadge"
    data-test-id="add_employee_badge-modal"
    @close="onCancel"
  >
    <div slot="body">
      <ui-select
        v-if="displayedBadge.length"
        :label="$t('business_profile.employee.modal_add_keycard_input_label')"
        :placeholder="$t('business_profile.employee.modal_add_keycard_input_label')"
        :options.prop="displayedBadge"
        data-test-id="badge-select"
        name="badgeDelivery"
        @selectoption="({ detail }) => setBadgeDeliveryUuid(detail)"
      />
      <ui-skeleton
        v-else
        class="mt-2"
      />
    </div>
    <div
      slot="footer"
      class="d-flex flex-wrap justify-content-end"
      data-test-id="ui-modal--footer"
    >
      <ui-button
        v-for="(button, index) in getModalButtons"
        :key="`button-${index}`"
        :class="[
          'my-1',
          {
            'ml-3': index
          }
        ]"
        v-bind="button.attributes"
        v-on="button.listeners"
      >
        {{ button.text }}
      </ui-button>
    </div>
  </ui-modal>
</template>

<script>
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import { addKeyCardStatus, getKeyCardBadgeList, keyCardList } from '@/stores/Company/CompanyMapper';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'AddEmployeeBadgeComponent',

  props: {
    // Child CS Operator UUID if it's possible
    csOperatorUuid: {
      type: String,
      required: true,
    },
    companyUuid: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: false,
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
    error: {
      type: String,
      required: true,
    },
  },

  setup() {
    const { fetchButtonSpecs } = useTheme();
    return { fetchButtonSpecs };
  },

  data() {
    return {
      badgeDeliveryUuid: null,
      badgeNumber: null,
      errorMessage: null,
    };
  },
  computed: {
    addKeyCardStatus,
    keyCardList,
    displayedBadge() {
      return map(this.keyCardList, badge => ({ value: badge.uuid, label: badge.badgeNumber })) || [];
    },
    getModalButtons() {
      return [
        {
          attributes: {
            ...this.fetchButtonSpecs({ buttonType: this.THEME_BUTTON_TYPES.SECONDARY }),
            'data-test-id': 'add-keycard-cancel_button',
          },
          listeners: {
            clickbutton: this.onCancel,
          },
          text: this.$t('buttons.cancel'),
        },
        {
          attributes: {
            ...this.fetchButtonSpecs(),
            loading: this.addKeyCardStatus.LOADING,
            disabled: this.addKeyCardStatus.LOADING || isNil(this.badgeDeliveryUuid),
            'data-test-id': 'add-key-card-submit_button',
          },
          listeners: {
            clickbutton: this.onClickAcceptButton,
          },
          text: this.$t('buttons.save'),
        },
      ];
    },
  },

  watch: {
    badgeDeliveryUuid(value) {
      this.errorMessage = !value ? this.error : null;
    },
  },

  async created() {
    await this.getKeyCardBadgeList();
  },

  methods: {
    getKeyCardBadgeList,
    getBadgeDeliveryUuid() {
      return this.badgeDeliveryUuid;
    },
    setBadgeDeliveryUuid(badgeUuid) {
      this.badgeDeliveryUuid = badgeUuid;
    },
    setErrorMessage(message) {
      this.errorMessage = message;
    },
    onClickAcceptButton() {
      this.onSuccess({ component: this });
    },
  },
};

</script>
