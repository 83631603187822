<template>
  <div class="container mt-5">
    <h2 class="mb-3">
      {{ $t('business_profile.company.title') }}
    </h2>
    <CompanyAdminSettingsForm />
  </div>
</template>

<script>
// Components
import CompanyAdminSettingsForm from '@/vue/components/organism/CompanyAdmin/CompanyAdminSettingsForm/CompanyAdminSettingsForm';

export default {
  components: {
    CompanyAdminSettingsForm,
  },
};

</script>
