<template>
  <ui-modal
    :open="isOpen"
    :size="SIZES.small"
    :header="$t('business_profile.employee.modal_deactivate_user.title')"
    closable
    data-test-id="deactivate_user-modal"
    @close="onCancel"
  >
    <div slot="body">
      {{ $t('business_profile.employee.modal_deactivate_user.text') }}
    </div>
    <div
      slot="footer"
      class="d-flex flex-wrap justify-content-end"
      data-test-id="ui-modal--footer"
    >
      <ui-button
        v-for="(button, index) in getModalButtons"
        :key="`button-${index}`"
        :class="[
          'my-1',
          {
            'ml-3': index
          }
        ]"
        v-bind="button.attributes"
        v-on="button.listeners"
      >
        {{ button.text }}
      </ui-button>
    </div>
  </ui-modal>
</template>

<script>
import { deactivateUserStatus } from '@/stores/Company/CompanyMapper';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'DeactivateUser',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    initialStatus: {
      type: String,
      default: '',
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { fetchButtonSpecs } = useTheme();
    return { fetchButtonSpecs };
  },
  computed: {
    deactivateUserStatus,

    getModalButtons() {
      return [
        {
          attributes: {
            ...this.fetchButtonSpecs({ buttonType: this.THEME_BUTTON_TYPES.SECONDARY }),
            'data-test-id': 'add-keycard-cancel_button',
          },
          listeners: {
            clickbutton: this.onCancel,
          },
          text: this.$t('buttons.cancel'),
        },
        {
          attributes: {
            ...this.fetchButtonSpecs({ buttonType: this.THEME_BUTTON_TYPES.DESTRUCTIVE }),
            class: 'mr-2',
            loading: this.deactivateUserStatus.LOADING,
            disabled: this.deactivateUserStatus.LOADING,
            'data-test-id': 'submit_button',
          },
          listeners: {
            clickbutton: this.onSuccess,
          },
          text: this.$t('business_profile.employee.modal_deactivate_user.confirm_button'),
        },
      ];
    },
  },
};
</script>
