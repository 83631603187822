import { FLEET_TYPES } from '@/constants/fleetTypes.const';

/**
 * Check if vehicle fleet type is dedicated
 * @param {string} fleetType
 * @return {boolean}
 */
export const isDedicatedFleet = fleetType => fleetType === FLEET_TYPES.dedicated;

/**
 * Check if vehicle fleet type is open
 * @param {string} fleetType
 * @return {boolean}
 */
export const isOpenFleet = fleetType => fleetType === FLEET_TYPES.open;
