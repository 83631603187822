<script>
import { compactArray, COUNTRIES, COUNTRIES_ISO_CODES } from '@emobg/web-utils';
import { ClickOutside } from '@emobg/vue-base';
import { checkVAT, countries as vatCountries } from 'jsvat';
import toLower from 'lodash/toLower';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import head from 'lodash/head';
export default {
  directives: {
    ClickOutside,
  },
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    selectPlaceholder: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    country: {
      type: String,
      default: null,
    },
    invalidTaxNumbers: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countries: [],
      isValidated: false,
      isValid: false,
      isActive: false,
      errorMessage: '',
      parsedCountry: '',
    };
  },
  computed: {
    borderColor() {
      if (this.isDisabled) {
        return this.GRAYSCALE.inkLighter;
      }

      if (this.isValidated) {
        return this.isValid
          ? this.COLORS.success
          : this.COLORS.danger;
      }

      return this.isActive
        ? this.COLORS.primary
        : this.GRAYSCALE.ground;
    },
    textColor() {
      return this.isDisabled
        ? this.GRAYSCALE.inkLighter
        : this.GRAYSCALE.ink;
    },
  },
  watch: {
    invalidTaxNumbers() {
      this.validate(this.modelValue);
    },
    country() {
      this.isValidated = false;
      this.errorMessage = null;
      this.parsedCountry = (this.country === COUNTRIES_ISO_CODES.switzerland)
        ? 'CHE'
        : this.country;
    },
    isActive(newStatus, oldStatus) {
      if (newStatus) {
        this.isValidated = false;
        this.errorMessage = null;
      } else if (oldStatus && !newStatus) {
        this.validate(this.modelValue);
      }
    },
  },
  created() {
    this.countries = map(COUNTRIES, ({ code }) => ({
      label: code,
      value: code,
    }));
    this.parsedCountry = this.country;
  },
  methods: {
    toLower,
    get,
    onChangeNumber(event) {
      const number = get(event, 'detail');
      if (number) {
        this.validate(number);
      } else {
        this.errorMessage = this.$t('refactor.company_signup.errors.mandatory');
        this.$emit('isValid', false);
        this.$emit('change', number);
        this.isValidated = true;
      }
    },
    validate(number) {
      const validationObject = checkVAT(
        `${this.parsedCountry}${number}`,
        vatCountries,
      );
      const errorMessages = [
        !validationObject.isValid
          ? this.$t('refactor.company_signup.errors.invalid_tax_number')
          : '',
        includes(this.invalidTaxNumbers, number)
          ? this.$t('refactor.company_signup.errors.duplicated_tax_number')
          : '',
      ];
      this.errorMessage = head(compactArray(errorMessages));
      this.isValid = !this.errorMessage;
      this.$emit('isValid', this.isValid);
      this.$emit('change', number);
      this.isValidated = true;
    },
  },
};
</script>
<template>
  <div
    v-click-outside="() => (isActive = false)"
    class="InputTax"
    data-test-id="input_tax-component"
  >
    <ui-label :label="label" />
    <div
      :class="`
        d-flex align-items-center emobg-border-radius-small emobg-border-1
        rounded-left emobg-border-color-${borderColor} ${
        isActive ? 'InputTax--active' : ''
      }`"
      data-test-id="input-container"
    >
      <div :class="`emobg-border-right-1 emobg-border-color-${borderColor} emobg-color-${textColor} d-flex align-items-center pr-3`">
        <div :class="`flag flag-s flag-${toLower(country)}`" />
        {{ parsedCountry }}
      </div>
      <ui-text-input
        :disabled="isDisabled"
        :value="modelValue"
        :placeholder="placeholder"
        name="tax"
        class="InputTax__text w-100"
        data-test-id="tax-input"
        reverse
        @click="isActive = true"
        @blurinput="onChangeNumber"
      />
    </div>
    <div
      v-if="!isValid && errorMessage"
      data-test-id="error_message-text"
      class="InputTax__error-message emobg-font-x-small emobg-color-danger"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.InputTax {
  &__error-message {
    padding: 2px 2px 2px 4px;
  }
}
</style>
