<template>
  <CompanyAdminBookingOverview />
</template>

<script>

// Components
import CompanyAdminBookingOverview from '@/vue/components/organism/CompanyAdmin/CompanyAdminBookingOverview/CompanyAdminBookingOverview';

export default {
  components: {
    CompanyAdminBookingOverview,
  },
};

</script>
