import { COLORS } from '@emobg/web-components';
import { FALLBACK_MESSAGE, isFalsyString } from '@emobg/web-utils';
import { ALGOLIA_REFINEMENT_OPTIONS } from '@emobg/vue-internal';

import { useTranslations } from '@/composable/App/Translations';
import AlgoliaTableItemActions from '@/components/AlgoliaTableItemActions/AlgoliaTableItemActions';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

const { $t } = useTranslations();

export function getTableConfig(rowActions) {
  return [
    {
      title: $t('business_profile.fleet.model'),
      transformResult: result => `${result.vehicle_brand} ${result.vehicle_model}`,
      width: 150,
    },
    {
      title: $t('business_profile.fleet.location'),
      property: 'location',
    },
    {
      title: $t('business_profile.fleet.license_plate'),
      property: 'license_plate',
      width: 150,
    },
    {
      title: $t('business_profile.fleet.status'),
      component: BadgeComponent,
      props: ({ active }) => ({
        color: active ? COLORS.success : COLORS.danger,
        text: active ? $t('business_profile.fleet.active') : $t('business_profile.fleet.inactive'),
      }),
    },
    {
      title: $t('business_profile.fleet.category'),
      transformResult: result => result.vehicle_category || result.vehicle_category,
    },
    {
      title: $t('business_profile.fleet.city'),
      property: 'city',
    },
    {
      title: $t('business_profile.fleet.mil'),
      property: 'mileage',
      folded: true,
    },
    {
      title: $t('business_profile.fleet.fuel'),
      property: 'vehicle_fuel_type',
      folded: true,
      transformValue: fuelType => $t(`fuel_types.${fuelType}`, fuelType),
    },
    {
      title: $t('business_profile.fleet.color'),
      property: 'color',
      folded: true,
      transformValue: color => $t(`vehicle_colors.${color}`, color),
    },
    {
      title: '',
      component: AlgoliaTableItemActions,
      props: result => ({ algoliaContext: result, rowActions }),
      width: 50,
    },
  ];
}

export function getFacetsConfigs() {
  return [
    {
      type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
      props: {
        title: $t('business_profile.fleet.facets.status'),
        attributeName: 'active',
        transformValue: active => (isFalsyString(active) ? $t('business_profile.fleet.inactive') : $t('business_profile.fleet.active')),
      },
    },
    {
      type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
      props: {
        title: $t('business_profile.fleet.facets.category'),
        attributeName: 'vehicle_category',
      },
    },
    {
      type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
      props: {
        title: $t('business_profile.fleet.facets.brand'),
        attributeName: 'vehicle_brand',
        toggled: false,
      },
    },
    {
      type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
      props: {
        title: $t('business_profile.fleet.facets.model'),
        attributeName: 'vehicle_model',
        toggled: false,
      },
    },
    {
      type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
      props: {
        title: $t('business_profile.fleet.facets.city'),
        attributeName: 'city',
        toggled: false,
        transformValue: city => (isFalsyString(city) ? FALLBACK_MESSAGE.noData : city),
      },
    },
    {
      type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
      props: {
        title: $t('business_profile.fleet.facets.location'),
        attributeName: 'location',
        transformValue: location => (isFalsyString(location) ? FALLBACK_MESSAGE.noData : location),
      },
    },
    {
      type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
      props: {
        title: $t('business_profile.fleet.facets.fuel_type'),
        attributeName: 'vehicle_fuel_type',
        toggled: false,
        transformValue: fuelType => $t(`fuel_types.${fuelType}`, fuelType),
      },
    },
  ];
}
