<script>
import { mapGetters } from 'vuex';
import { aclService } from '@emobg/access-utils';
import { MuiAlgoliaList } from '@emobg/vue-internal';
import { external } from '@emobg/web-api-client';
import { DELAY } from '@emobg/web-utils';
import { ACTIVE_DEACTIVE_FLEET_VEHICLE } from '@/constants/permissions';
import { nameSpace as profileNameSpace } from '@Profile/store';
import { errorNotification } from '@/handlers/errorHandler.const';
import { DEFAULT_HITS_PER_PAGE } from '@/constants/algoliaTable';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { useAlgolia } from '@/composable/Algolia/useAlgolia';
import { getFacetsConfigs, getTableConfig } from './helpers/fleetList';
import { LABELS } from './constants/fleetList.const';

const VEHICLE_ENDPOINT_STATUS = {
  ENDPOINT_VEHICLE_ACTIVATE: 1,
  ENDPOINT_VEHICLE_DEACTIVATE: 0,
};

export default {
  name: 'FleetListTab',
  components: {
    MuiAlgoliaList,
  },
  setup() {
    const {
      apiKey,
      algoliaConfig,
      initAlgolia,
      refreshAlgoliaTable,
      getAlgoliaLabels,
    } = useAlgolia();
    const { notifyError, notifySuccess } = useNotifications();
    const appRootElement = document.getElementById('app');
    return {
      apiKey,
      algoliaConfig,
      notifyError,
      notifySuccess,
      initAlgolia,
      refreshAlgoliaTable,
      getAlgoliaLabels,
      appRootElement,
      LABELS,
    };
  },
  data() {
    return {
      isListLoading: false,
      isPageLoaded: false,
    };
  },
  computed: {
    ...mapGetters(profileNameSpace, {
      currentProfile: 'getCurrentProfile',
    }),
  },
  async created() {
    const canUpdateVehicles = aclService.hasPermissions(ACTIVE_DEACTIVE_FLEET_VEHICLE);
    const dedicatedFleetCSOperatorUUID = this.currentProfile.getCompanyData().getDedicatedFleetCSOperatorUUID();
    this.TABLE_FILTER = `dedicated_cs_operator_uuid:'${dedicatedFleetCSOperatorUUID}' AND api:1`;
    await this.initAlgolia('vehicles', this.TABLE_FILTER);
    this.ALGOLIA_QUERY_PARAMETERS = {
      hitsPerPage: DEFAULT_HITS_PER_PAGE,
    };
    this.TABLE_LABELS = this.getAlgoliaLabels(this.LABELS);
    this.ITEMS_ACTIONS = [
      {
        label: this.$t('business_profile.action.desactivate'),
        method: this.onDeactivate,
        isVisible: ({ active }) => active && canUpdateVehicles,
        class: 'emobg-color-danger',
      },
      {
        label: this.$t('business_profile.action.activate'),
        method: this.onActivate,
        isVisible: ({ active }) => !active && canUpdateVehicles,
      },
    ];
    this.TABLE_CONFIG = getTableConfig(this.ITEMS_ACTIONS);
    this.TABLE_FACETS = getFacetsConfigs();
    this.isPageLoaded = true;
  },
  methods: {
    onActivate(vehicle) {
      this.$emit('actionStarted');
      this.request(
        VEHICLE_ENDPOINT_STATUS.ENDPOINT_VEHICLE_ACTIVATE,
        vehicle.uuid,
      );
    },
    onDeactivate(vehicle) {
      this.$emit('actionStarted');
      this.request(
        VEHICLE_ENDPOINT_STATUS.ENDPOINT_VEHICLE_DEACTIVATE,
        vehicle.uuid,
      );
    },
    async request(status, vehicleUuid) {
      const requestApi = status
        ? external.fleetVehicles.putActivateVehicle
        : external.fleetVehicles.putDeactivateVehicle;

      const modalText = status
        ? this.$t('modal.vehicle_status.success_activated')
        : this.$t('modal.vehicle_status.success_deactivated');

      this.isListLoading = true;

      try {
        await requestApi(vehicleUuid);
        this.notifySuccess({
          text: modalText,
        });
        setTimeout(async () => {
          await this.refreshAlgoliaTable(this.$refs.muiAlgoliaFleetList, DELAY.long);
          this.isListLoading = false;
        }, DELAY.long);
      } catch (error) {
        this.notifyError(errorNotification(error));
        this.isListLoading = false;
      }
    },
  },
};

</script>

<template>
  <div class="container mt-5">
    <h2 class="mb-3">
      {{ $t('business_profile.fleet.title') }}
    </h2>
    <p class="emobg-body-m-regular mb-5">
      {{ $t('business_profile.fleet.subtitle') }}
    </p>
    <div class="position-relative">
      <MuiAlgoliaList
        v-if="isPageLoaded"
        ref="muiAlgoliaFleetList"
        :config="algoliaConfig"
        :loading="isListLoading"
        :api-key="apiKey"
        :filters="TABLE_FILTER"
        :facets="TABLE_FACETS"
        :table-config="TABLE_CONFIG"
        :search-placeholder="TABLE_LABELS.searchTable"
        :labels="TABLE_LABELS"
        :no-data-label="FALLBACK_MESSAGE.dash"
        :query-parameters="ALGOLIA_QUERY_PARAMETERS"
        :scroll-target="appRootElement"
        index="vehicles"
        class="FleetList"
      />
    </div>
  </div>
</template>

