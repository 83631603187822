export const PERMISSIONS = {
  ACTIVATE_DEACTIVATE_EMPLOYEE: 'activate_deactivate_employees',
  ACTIVE_DEACTIVE_FLEET_VEHICLE: 'activate_deactivate_fleet_vehicles',
  ADD_PAYMENT_METHOD: 'add_payment_method',
  CANCEL_CS_LD_BOOKING_ON_BEHALF_EMPLOYEES: 'cancel_cs_ld_booking_on_behalf_of_other_employees',
  CANCEL_CS_LD_OWN_BOOKINGS: 'cancel_cs_ld_own_bookings',
  CREATE_BOOKING_ON_BEHALF_EMPLOYEES: 'create_booking_on_behalf_of_other_employees',
  CREATE_CS_LD_OWN_BOOKINGS: 'create_cs_ld_own_bookings',
  EDIT_BOOKING_ON_BEHALF_EMPLOYEES: 'edit_booking_on_behalf_of_other_employees',
  EDIT_COMPANY_SETTINGS: 'edit_company_settings',
  EDIT_OWN_CS_BOOKINGS: 'edit_own_cs_bookings',
  EDIT_PAYMENT_METHOD: 'edit_payment_method',
  EDIT_USER_ROLE: 'edit_user_role',
  EXPORT_COMPANY_INVOICES: 'export_company_invoices',
  EXPORT_COMPANY_REPORTS: 'export_company_reports',
  INTERVENTION_BOOKING_TYPE: 'intervention_booking_type',
  INVITE_EMPLOYEES: 'invite_employees',
  PAY_PENDING_INVOICES: 'pay_pending_invoices',
  SELECT_COMPANY_TARIFF: 'select_company_tariff',
  REGULAR_BOOKING_TYPE: 'regular_booking_type',
  UNLOCK_VEHICLE_OWN_BOOKINGS: 'unlock_vehicle_own_booking',
  VIEW_BOOKINGS_COMPANY_EMPLOYEES: 'view_bookings_of_company_employees',
  VIEW_COMPANY_INVOICES: 'view_company_invoices',
  VIEW_COMPANY_REPORTS: 'view_company_reports',
  VIEW_COMPANY_SETTINGS: 'view_company_settings',
  VIEW_COMPANY_TARIFF: 'view_company_tariff',
  VIEW_EMPLOYEES: 'view_employees',
  VIEW_FLEET_VEHICLES: 'view_fleet_vehicles',
  VIEW_INTERVENTION_BOOKINGS_COMPANY_EMPLOYEES: 'view_intervention_bookings_of_company_employees',
  VIEW_OWN_CS_BOOKINGS: 'view_own_cs_bookings',
  VIEW_PAYMENT_METHODS: 'view_payment_methods',
  ACCESS_TO_BACKOFFICE: 'access_to_backoffice',
  EDIT_PERSONAL_SETTINGS: 'edit_personal_settings',
  VIEW_OWN_BEHALF_EMPLOYEE_BOOKINGS: 'view_own_behalf_employee_bookings',
  EDIT_OWN_BEHALF_EMPLOYEE_BOOKINGS: 'edit_own_behalf_employee_bookings',
};
export const REQUIRED_PERMISSION_COMPANY_ADMIN = [
  [PERMISSIONS.VIEW_COMPANY_REPORTS],
  [PERMISSIONS.VIEW_FLEET_VEHICLES],
  [PERMISSIONS.VIEW_COMPANY_INVOICES],
  [PERMISSIONS.VIEW_PAYMENT_METHODS],
  [PERMISSIONS.VIEW_COMPANY_SETTINGS],
  [PERMISSIONS.VIEW_BOOKINGS_COMPANY_EMPLOYEES],
];

export const REQUIRED_PERMISSION_COMPANY_ADMIN_CIRCLES = [
  [PERMISSIONS.VIEW_EMPLOYEES],
];
