<script setup>
import { computed } from 'vue';
import { isFalsyString } from '@emobg/web-utils';

const props = defineProps({
  phone: {
    type: String,
    default: '',
  },
});

const hasPhone = computed(() => !isFalsyString(props.phone));
const tel = computed(() => (hasPhone.value ? `tel:${props.phone}` : '#'));
</script>

<template>
  <a
    v-if="hasPhone"
    :href="tel"
    class="emobg-link-primary"
  >{{ props.phone }}</a>
  <span v-else>{{ FALLBACK_MESSAGE.dash }}</span>
</template>
