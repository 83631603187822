<script>
import { computed, ref } from 'vue';

import config from '@/config';
import TariffsSelectorComponent from '@Tariffs/components/NewTariffSelector/TariffsSelectorComponent';

import PaymentMethodsHubListener from '@Shared/Payments/PaymentMethodsHub/PaymentMethodsHubListener';

export default {
  name: 'TariffsTab',
  components: {
    TariffsSelectorComponent,
    PaymentMethodsHubListener,
  },

  provide() {
    return {
      paymentSuccess: computed(() => this.paymentSuccessData),
    };
  },

  setup() {
    const isPaymentSuccess = ref(false);
    const providePaymentResponse = ref(null);

    const paymentSuccessData = ref({
      isValid: false,
      response: null,
    });

    return {
      isPaymentSuccess,
      providePaymentResponse,
      paymentSuccessData,
    };
  },

  created() {
    this.appName = config.data.appName;
  },

  methods: {
    onPaymentAuthorised(response) {
      this.isPaymentSuccess = true;
      this.providePaymentResponse = response;

      this.paymentSuccessData = {
        isValid: true,
        response,
      };
    },

    onPaymentRefused(response) {
      this.isPaymentSuccess = false;
      this.providePaymentResponse = response;

      this.paymentSuccessData = {
        isValid: false,
        response,
      };
    },
  },
};
</script>

<template>
  <div
    data-test-id="business-profile-tariffs-tab"
    class="TariffsTab container-fluid my-5"
  >
    <h2 class="text-center mb-5">
      {{ $t('personal_profile.tariff.new_title', { operator: appName }) }}
    </h2>

    <TariffsSelectorComponent is-business-profile />

    <PaymentMethodsHubListener
      @on:payment-authorised="onPaymentAuthorised"
      @on:payment-refused="onPaymentRefused"
    />
  </div>
</template>

