<template>
  <div data-test-id="edit-dl-expiration-modal">
    <ui-modal
      :size="SIZES.small"
      :open="isOpen"
      :header="$t('business_profile.employee.license_date_modal.title')"
      closable
      data-test-id="edit_license_expiration_date-modal"
      @close="onCancel"
    >
      <div slot="body">
        <div class="mt-2 d-flex align-items-center justify-content-start">
          <ui-radio
            :value="isLicenseExpire"
            :option="false"
            data-test-id="dl_not_expire-radio-button"
            class="pt-1"
            name="licenseExpire"
            @changevalue="({ detail }) => isLicenseExpire = detail"
          />
          <ui-label :label="$t('business_profile.employee.license_date_modal.opt_not_expire_date')" />
        </div>
        <div class="d-flex align-items-center justify-content-start">
          <ui-radio
            :value="isLicenseExpire"
            :option="true"
            data-test-id="dl_expiry_date-radio-button"
            name="licenseExpire"
            class="pt-1"
            @changevalue="({ detail }) => isLicenseExpire = detail"
          />
          <ui-label :label="$t('business_profile.employee.license_date_modal.opt_has_expire_date')" />
        </div>
        <div class="mt-2">
          <ui-datetimepicker
            v-if="isLicenseExpire"
            :date.prop="date"
            :label="$t('business_profile.employee.license_date_modal.label_date')"
            skiptime
            data-test-id="dl_expiry_date-datepicker"
            @datechanged="({ detail }) => date = detail"
          />
        </div>
      </div>
      <div
        slot="footer"
        class="d-flex flex-wrap justify-content-end"
        data-test-id="ui-modal--footer"
      >
        <ui-button
          v-for="(button, index) in getModalButtons"
          :key="`button-${index}`"
          :class="[
            'my-1',
            {
              'ml-3': index
            }
          ]"
          v-bind="button.attributes"
          v-on="button.listeners"
        >
          {{ button.text }}
        </ui-button>
      </div>
    </ui-modal>
  </div>
</template>

<script>
import get from 'lodash/get';
import { DATE_FORMAT, toBoolean } from '@emobg/web-utils';
import { errorNotification } from '@/handlers/errorHandler.const';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import {
  editLicenseExpirationDateStatus,
  getCompanyData,
  putEditLicenseExpirationDate,
} from '@/stores/Company/CompanyMapper';
import { PROFILE_TYPES } from '@/constants/profiles.const';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'EditLicenseExpirationDate',

  props: {
    userUuid: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    onCancel: {
      type: Function,
      default: () => {
      },
    },
    onSuccess: {
      type: Function,
      default: () => {
      },
    },
  },
  setup() {
    const { fetchButtonSpecs } = useTheme();
    const { notifyError } = useNotifications();
    return {
      fetchButtonSpecs,
      notifyError,
    };
  },
  data() {
    return {
      isFormValid: false,
      isLicenseExpire: false,
      date: null,
    };
  },

  computed: {
    getCompanyData,
    editLicenseExpirationDateStatus,
    getModalButtons() {
      return [
        {
          attributes: {
            ...this.fetchButtonSpecs({ buttonType: this.THEME_BUTTON_TYPES.SECONDARY }),
            disabled: this.editLicenseExpirationDateStatus.LOADING,
            class: 'mr-2',
            'data-test-id': 'cancel-button',
          },
          listeners: {
            clickbutton: this.onCancel,
          },
          text: this.$t('buttons.cancel'),
        },
        {
          attributes: {
            ...this.fetchButtonSpecs(),
            loading: this.editLicenseExpirationDateStatus.LOADING,
            disabled: this.isLicenseExpire && !moment(this.date).isValid(),
            class: 'mr-2',
            'data-test-id': 'submit-button',
          },
          listeners: {
            clickbutton: this.updateLicenseExpiryDate,
          },
          text: this.$t('business_profile.employee.license_date_modal.update_button'),
        },
      ];
    },
  },
  watch: {
    isLicenseExpire(newValue) {
      if (!newValue) {
        this.date = null;
      }
    },
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
  },

  methods: {
    toBoolean,
    putEditLicenseExpirationDate,

    async updateLicenseExpiryDate() {
      if (toBoolean(this.isLicenseExpire)) {
        if (!this.date) {
          return;
        }
      }

      try {
        const dedicatedUuid = get(this.getCompanyData, 'dedicated_cs_operator_uuid');
        const csOperatorUuid = dedicatedUuid || get(this.getCompanyData, 'cs_operator_uuid');
        await this.putEditLicenseExpirationDate({
          userUuid: this.userUuid,
          checkedParams: {
            expiration: this.isLicenseExpire ? this.date.format(DATE_FORMAT.date) : null,
            cs_operator_uuid: csOperatorUuid,
            user_profile_type: PROFILE_TYPES.business,
          },
        });
        this.onSuccess();
      } catch (error) {
        this.notifyError(errorNotification(error));
        this.onCancel();
      }
    },
  },
};
</script>
