export const LABELS = Object.freeze({
  searchTable: 'business_profile.employee.search_employees_placeholder',
  showFilters: 'buttons.show_filters',
  hideFilters: 'buttons.hide_filters',
  hide: 'algolia_table.hide',
  show: 'algolia_table.show',
  page: 'algolia_table.page',
  of: 'algolia_table.of',
  filters: 'algolia_table.filters',
  clearAll: 'algolia_table.clear',
  top: 'UiComponents.MuiAlgoliaTable.go_to_top_button.text',
  horizontalFilters: 'UiComponents.MuiAlgoliaTable.filters.horizontal_filters',
  verticalFilters: 'UiComponents.MuiAlgoliaTable.filters.vertical_filters',
});
