<template>
  <ui-modal
    :size="SIZES.small"
    :open="isOpen"
    :header="$t('modal.invite_people.header')"
    closable
    data-test-id="invite_people-modal"
    @close="onCancel"
  >
    <div slot="body">
      <h4 class="emobg-font-medium emobg-font-weight-bold">
        {{ $t('modal.invite_people.title') }}
      </h4>
      <ui-text-area
        :value="invitationEmails"
        name="invitations"
        placeholder="johndoe@example.com, janedoe@example.com, ..."
        class="w-100"
        rows="5"
        data-test-id="invitation_emails-textarea"
        @changevalue="event => invitationEmails = event.detail"
      />
      <p class="emobg-font-small emobg-color-ink-light">
        {{ $t('modal.invite_people.hint') }}
      </p>
    </div>
    <div
      slot="footer"
      class="d-flex flex-wrap justify-content-end"
      data-test-id="ui-modal--footer"
    >
      <ui-button
        v-for="(button, index) in getModalButtons"
        :key="`button-${index}`"
        :class="[
          'my-1',
          {
            'ml-3': index
          }
        ]"
        v-bind="button.attributes"
        v-on="button.listeners"
      >
        {{ button.text }}
      </ui-button>
    </div>
  </ui-modal>
</template>

<script>
import trim from 'lodash/trim';
import reduce from 'lodash/reduce';
import forEach from 'lodash/forEach';
import size from 'lodash/size';
import split from 'lodash/split';
import { compactArray, isEmail } from '@emobg/web-utils';

import { userUuid } from '@/stores/User/UserData/UserDataMapper';
import {
  inviteAnEmployeeStatus,
  postInviteAnEmployee,
} from '@/stores/Company/CompanyMapper';

import { SEGMENT_EVENTS } from '@/vue/constants';
import { useSegment } from '@/composable/Segment/segment';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'InvitePeople',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
  },

  setup() {
    const { trackSegment } = useSegment();
    const { notifyError, notifySuccess } = useNotifications();
    const { fetchButtonSpecs } = useTheme();

    return {
      fetchButtonSpecs,
      trackSegment,
      notifyError,
      notifySuccess,
    };
  },

  data() {
    return {
      invitationEmails: '',
    };
  },

  computed: {
    userUuid,
    inviteAnEmployeeStatus,

    invitationsArray() {
      const invitationsSanitize = trim(this.invitationEmails.replace(/[\r,\n]$/gm, ''));
      return compactArray(split(invitationsSanitize, ','));
    },
    areEmailsValid() {
      return size(this.invitationsArray)
        && reduce(this.invitationsArray, (acc, invitationEmail) => acc && isEmail(trim(invitationEmail)).isValid, true);
    },

    getModalButtons() {
      return [
        {
          attributes: {
            ...this.fetchButtonSpecs({ buttonType: this.THEME_BUTTON_TYPES.SECONDARY }),
            'data-test-id': 'invite-people-cancel_button',
          },
          listeners: {
            clickbutton: this.onCancel,
          },
          text: this.$t('buttons.cancel'),
        },
        {
          attributes: {
            ...this.fetchButtonSpecs(),
            loading: this.inviteAnEmployeeStatus.LOADING,
            disabled: !this.areEmailsValid || this.inviteAnEmployeeStatus.LOADING,
            'data-test-id': 'invite-people-submit_button',
          },
          listeners: {
            clickbutton: this.sendInvitations,
          },
          text: this.$t('buttons.send'),
        },
      ];
    },
  },

  methods: {
    postInviteAnEmployee,

    async sendInvitations() {
      this.valid = false;
      try {
        await this.postInviteAnEmployee({ userUuid: this.userUuid, emails: { invitee_emails: this.invitationsArray } });

        this.invitationEmails = '';
        this.valid = false;
        this.notifySuccess({
          text: this.$t('business_profile.employee.invitation_success'),
        });
        forEach(this.invitationsArray, email => {
          this.trackSegment({
            name: SEGMENT_EVENTS.B2B_INVITE_EMPLOYEE,
            data: {
              email,
            },
          });
        });
      } catch (error) {
        this.notifyError({
          text: this.$t('notifications.whooops'),
        });
      }
      this.onCancel();
    },
  },
};
</script>
