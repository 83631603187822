<script setup>
import { computed } from 'vue';

const props = defineProps({
  email: {
    type: String,
    default: '',
  },
});

const href = computed(() => (props.email ? `mailto:${props.email}` : '#'));
</script>

<template>
  <div
    v-if="props.email"
    class="ellipsis"
  >
    <a
      :href="href"
      class="emobg-link-primary"
      data-cs-mask
    >{{ props.email }}</a>
  </div>
  <span v-else>{{ FALLBACK_MESSAGE.dash }}</span>
</template>
