<script>
import has from 'lodash/has';
import { FALLBACK_MESSAGE } from '@emobg/web-utils';

export default {
  name: 'CostAllocationDetailComponent',

  props: {
    costAllocation: {
      type: Array,
      default: () => [],
      validator: (arrCostAllocation = []) => arrCostAllocation.every(item => has(item, 'name') && has(item, 'code')),
    },
  },

  created() {
    this.FALLBACK_MESSAGE = FALLBACK_MESSAGE;
  },
};
</script>

<template>
  <div class="CostAllocationFrameComponent d-flex flex-column">
    <template v-if="costAllocation.length">
      <div
        v-for="(field, index) in costAllocation"
        :ref="`costAllocation${index}`"
        :key="index"
      >
        {{ field.name }}
        <span v-if="field.code">- {{ field.code }}</span>
      </div>
    </template>
    <span v-if="!costAllocation.length">
      {{ FALLBACK_MESSAGE.dash }}
    </span>
  </div>
</template>
